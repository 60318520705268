@import "../../../index.scss";
.containerOne {
  display: flex;
  height: 40px;
  // border: 1px solid #e6e6e6;
  width: 100%;
  align-items: center;
  gap: 10px;
  padding: 0 15px;
  box-sizing: border-box;
  border-radius: $borderRadius;
  margin: 0 3px;

  //active on breadcumb means that loop is open
  &.active {
    width: fit-content;
    width: 0;
    padding: 0;
    overflow: hidden;
    border: none;
  }
  span {
    //font regular
    font-family: "Regular";
    font-size: 12px;
    color: #707070;
    cursor: pointer;
    min-width: fit-content;
    vertical-align: baseline;
  }
}
.containerTwo {
  display: flex;
  margin-top: -30px;
  height: 38px;
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
  gap: 10px;
  padding-left: 15px;
  box-sizing: border-box;
  span {
    //font regular
    font-family: "Regular";
    font-size: 12px;
    color: #707070;
    cursor: pointer;
  }
}
