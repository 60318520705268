@import "../../index.scss";
.container {
  display: flex;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #ececec;
  background-color: white;
  text-transform: uppercase;
  cursor: pointer;
  width: 345px;
  padding: 24px 31px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: $borderRadius;
  h3 {
    font-size: 16px;
    font-weight: 700;
    color: #548ad3;
  }
  > div {
    width: 112px;
    height: 105px;
    > img {
      margin: auto;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }
}
