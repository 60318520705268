.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 700px;
  @media screen and (max-width: 992px) {
    width: 100%;
    box-sizing: border-box;
  }
  > p {
    color: #939ea8;
    font-size: 16px;
    font-weight: 400;
    font-family: "Roboto";
  }
  > div:last-of-type {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow: scroll;
    .item {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: flex-start;
      padding: 8px 30px;
      gap: 15px;
      border-top: 1px solid #e6e6e6;
      border-bottom: 1px solid #e6e6e6;
      &:not(:first-of-type) {
        border-top: none;
      }
      span {
        color: #575757;
        font-size: 18px;
        font-weight: 700;
        font-family: "Akrobat";
      }
      > div:last-of-type {
        margin-left: auto;
      }

      .img_container {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
    }
  }
}
