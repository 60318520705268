@import "../../../index.scss";
.sliding_container {
  position: absolute;
  width: 270px;
  height: calc(100% - 20px);
  background-color: white;
  border-radius: $borderRadius;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: scroll;

  left: 80px; // Offset to avoid overlapping icons
  z-index: 1; // Ensure icons remain above the sliding container
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 10;
  // background-color: #f8f8f8;

  .el {
    position: relative;
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 9px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 2; // Higher than the sliding container

    svg {
      color:  #808d98;
    }

    &.active {
      background: linear-gradient(225deg, #6431e6 0%, #30aae5 100%);

      svg {
        filter: brightness(0) invert(1);
        width: 28px;
        height: 28px;
      }

      span {
        color: #ffffff;
      }
    }

    span {
      font-size: 10px;
      font-weight: bold;
      color: #575757;
      margin-top: 3px;
    }
  }
}
