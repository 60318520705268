.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  .tools {
    width: calc(100% - 12px);
    display: flex;
    padding: 0 55px;
  }
  .children {
    display: flex;
    margin-left: 10px;
  }
}
