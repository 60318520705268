@import "../../../index.scss";

.container {
  width: 100%;

  .chips_container {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      min-width: 180px;
      text-transform: uppercase;
      font-family: "Akrobat";
      color: $textMainColor;
      font-size: 22px;
      font-weight: 600;
    }
  }
}
