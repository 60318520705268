.container {
  display: flex;
  flex-wrap: wrap;

  &.three_ex_small {
    gap: 20px;
  }
  &.two_ex_small {
    gap: 24px;
  }
  &.ex_small {
    gap: 24px;
  }
  &.small {
    gap: 24px;
  }
  &.medium {
    gap: 24px;
  }
}
