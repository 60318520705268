.container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0 15px;
  .color_pallet_container {
    position: relative;
    cursor: pointer;
    .current_color {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .pallet {
      position: absolute;
      top: 40px;
      z-index: 99;
    }
  }
  .alpha_slider {
    margin-top: 35px;
  }
  .icon_container {
    width: 30px;
    height: 30px;
    svg {
      color: #707070;
      height: 100%;
      width: 100%;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        color: #548ad3;
      }
    }
  }
  .slider_container {
    width: 300px;
  }
}
.horizontal {
  transform: rotate(90deg);
}
