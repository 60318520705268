.container {
  width: 100%;
  max-width: 750px;
  h3 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    color: #575757;
    text-transform: uppercase;
  }
  .table {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 750px;
    // width: 100%;
    border: 1px solid #e6e6e6;
    padding: 40px 32px 20px;
    margin-top: 20px;
    background-color: white;
    .top {
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        padding: 15px 0;
        border-bottom: 1px solid#E6E6E6;
        span:first-of-type {
          width: 150px;
          color: #939ea8;
          font-size: 14px;
          font-weight: 700;
        }
        span:last-of-type {
          font-size: 16px;
          font-weight: 700;
          color: #575757;
        }
      }
    }
    .bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .other {
        color: #575757;
        text-decoration: none;
      }
      span {
        font-size: 16px;
        color: #548ad3;
        text-decoration: underline;
      }
      span:last-of-type {
        margin-left: auto;
      }
    }
  }
}
