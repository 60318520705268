@import "../../index.scss";
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background: $gradien2OClockTo7Oclock;
  border-radius: 50%;
  transition: 0.25s ease-in-out;
  border: 1px solid transparent;
  cursor: pointer;
  svg {
    color: white;
  }
  &:hover {
    border: 1px solid $mainColor;
    svg {
      color: white;
    }
  }
}
