.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .nav {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
  }
  .inner_container {
    display: flex;
    overflow: hidden;
    gap: 50px;
    justify-content: space-evenly;
    @media screen and (max-width: 1500px) {
      display: none;
    }
  }

  .inner_container_mobile {
    display: none;

    @media screen and (max-width: 1500px) {
      display: flex;
      justify-content: center;
      max-width: 700px;
      margin: 0 auto;
    }
    .subscription_swiper {
      display: flex;
      justify-content: center;
      .swiper_slide {
        display: flex;
        justify-content: center;
      }
    }
  }
}
