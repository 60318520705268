.container {
  display: flex;
  justify-content: center;
  gap: 60px;
  padding: 15px;
  height: calc(100vh - 162px);
  overflow: hidden;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}
