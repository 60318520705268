.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 20px;
  border-radius: 30%;
  background-color: #e6e6e6;
  font-size: 10px;
}
