@import "../../../index.scss";

.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    flex-direction: column;
    margin-top: 18vh;
    width: 70%;
    padding-inline: 10% 10%;
    overflow-x: scroll;
    @media screen and (max-width: 1080px) {
      width: 100%;
      margin-top: 8vh;
      overflow: scroll;
    }

    .header {
      display: flex;
      flex-direction: column;
      gap: 12px;
      h2 {
        font-size: 24px;
        font-weight: 900;
        color: #548ad3;
      }

      h3 {
        padding: 0;
        text-transform: capitalize;
        font-weight: 900;
        font-size: 36px;
        color: $textSecondaryColor;
        margin-bottom: 5vh;
      }
    }

    > .payment_success_container {
      border: 1px solid #e6e6e6;
      border-radius: 8px;
      width: 100%;
      height: 186px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;

      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
      color: #575757;
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
  .right {
    width: 30%;
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
}
