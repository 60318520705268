.openMore {
  display: flex;
  position: relative;
  svg {
    margin-left: auto;
    transition: all 0.25s ease-in-out;
    &:hover {
      color: white;
    }
  }
}
