.canvas_container {
  width: 100%;
  position: relative;
}
.left_top_panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 15px;
  left: 10px;
  top: 100px;
  width: 65px;
  z-index: 9;
}
.left_bottom_panel {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 15px;
  left: 10px;
  bottom: 25px;
  width: 65px;
  z-index: 9;
}
.views {
  position: absolute;
  right: 50px;
  top: 150px;
  z-index: 9;
  color: #6274854d;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  gap: 10px;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
