@import "../../index.scss";
.container {
  transition: all 0.25s ease-in-out;

  &:hover {
    .img_container {
      span {
        color: #548ad3;
      }
    }
  }
  cursor: pointer;
  .img_container {
    width: 90px;
    height: 140px;
    background-color: white;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $borderRadius;

    span {
      font-size: 32px;
      font-weight: 700;
      line-height: 38px;
      color: #939ea8;
    }
  }
  .info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
      font-size: 16px;
      font-family: "Akrobat";

      &:first-of-type {
        color: #575757;
        font-weight: 600;
      }
      &:last-of-type {
        color: #939ea8;

        font-weight: 400;
      }
    }
  }
}
