.container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 30px;

  span {
    font-size: 16px;
    text-align: center;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .style {
    height: 35px;
    width: 2px;
    background-color: #ffffff80;
  }
  .div_style {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .seperator {
    margin-right: auto;
  }
  svg {
    color: white;
    cursor: pointer;
  }
}
.button_div {
  display: flex;
  gap: 15px;
  margin-left: auto;
}
.button {
  display: inline-flex;
  width: fit-content;
  height: 40px;
  box-sizing: border-box;
  // background: #fff;
  // border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 14px;
  column-gap: 7px;
  cursor: pointer;
  span {
    color: white;
    font-size: 16px;
  }
  svg {
    color: white;
  }
}
