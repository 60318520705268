.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-height: 225px;

  .list_container {
    display: flex;
    gap: 50px;
    margin-top: 5px;
    flex-wrap: wrap;
    .map_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 24px;
    }
  }
}
