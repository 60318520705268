.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 15px;
  .input {
    width: 100%;
  }
  .half_container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    > div {
      border: 1px solid #e6e6e6;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 4px;
      height: 40px;
      box-sizing: border-box;
      cursor: pointer;
      span {
        font-family: "Akrobat";
        font-size: 16px;
        font-weight: 600;
        color: #575757;
      }
      svg {
        color: #939ea8;
      }
    }
  }
}
