@import "../../../../index.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 28px 0 35px;
  height: 100%;
  width: 100%;

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    border: 5px solid transparent;
    background: $gradient3 border-box;
    mask: linear-gradient(#000 0 0) padding-box, linear-gradient(#000 0 0);
    mask-composite: exclude;
  }

  span {
    width: 150px;
    text-align: center;
  }

  > span:first-of-type {
    color: #548ad3;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
  > span:nth-child(2),
  > span:nth-child(3) {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }
  > span:last-of-type {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #939ea8;
    text-align: center;
    max-width: 360px;
    width: 100%;
  }
}
.icons {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 36px;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
