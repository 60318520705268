@import "../../index.scss";

.container {
  width: 40px;
  height: 40px;
  border: 2px solid $mainColor;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    width: 80px;
    height: 80px;
    border-radius: 0;
  }
}
