@import "../../../index.scss";
.container {
  cursor: pointer;
  // overflow: hidden;
  position: relative;
  z-index: 999;

  transition: all 0.2s ease-in-out;
  &.three_ex_small {
    gap: 20px;
  }
  &.two_ex_small {
  }
  &.ex_small {
  }
  &.small {
    width: 130px;
  }
  &.medium {
    gap: 24px;
    width: 160px;
  }
  &:hover {
    .top {
      background-color: $mainColor;
    }
    // border: 1px solid $mainColor;
  }
  .top {
    width: 45%;
    height: 5px;
    background-color: #e6e6e6;
    margin-left: 15px;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    transition: all 0.25s ease-in-out;
    padding: 12px 15px 12px 15px;
    border-radius: $borderRadius;
    height: 100%;
    z-index: 9;
    height: 50px;
    > div {
      display: flex;
      align-items: center;
      position: relative;
    }
    &:hover {
      background-color: #f8f8f8;
      // border: 1px solid $mainColor;
    }
    span {
      font-size: 16px;
      line-height: 19px;
      color: black;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding-right: 5px;
    }
  }
  .arrow {
    transition: 0.25s ease-in-out;
    &.open {
      transform: rotate(180deg);
    }
  }
}
