@import "../../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  .input {
    margin-bottom: 0px;
    background-color: #f8f8f8;
    border-radius: $borderRadius;
  }

  .folder_top {
    position: absolute;
    background-color: #e6e6e6;
    margin-top: 11px;
    margin-left: 10px;
    height: 11px;
    width: 45%;
    border-radius: 6px;
  }

  .folder_tree_container {
    position: relative;
    border-bottom: none;
    border-top: none;
    margin-top: 0;

    & > ul {
      height: 130px;
      overflow: scroll;
      background-color: white;
      z-index: 10;
      border: 1px solid;
      border-color: #e6e6e6;
      border-radius: $borderRadius;
    }
    .folder_tree {
      width: 100%;
      position: absolute;
    }
  }
}
