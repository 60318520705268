@import "../../../index.scss";
.container {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  max-height: 80vh;
  overflow-y: scroll;

  .img_container {
    width: 20px;
    height: 20px;
  }
  > .header {
    position: static;
    top: 0;
    text-align: center;
    padding: 20px 0;
    background: linear-gradient(
      90deg,
      #592fe6 0%,
      #6fc9e0 35%,
      #73d5db 55%,
      #76ded7 69%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );
    margin: 2px;
    border-radius: $borderRadius;
    h4 {
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      color: white;
      margin: auto;
    }
  }
  .content {
    display: flex;
    padding: 0 57px;
    border-left: 1px solid #e6e6e6;
    .left {
      width: 830px;
    }
    .right {
      display: flex;
      flex-direction: column;
      width: 330px;
      padding: 38px 0 28px 47px;
      gap: 10px;
      border-left: 1px solid #e6e6e6;

      .header {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;

        h4 {
          font-size: 16px;
          font-weight: 700;
          line-height: 19px;
          color: #548ad3;
        }
      }
      .orientation {
        margin-top: 20px;
        .paper_orientation {
          width: 58px;
          height: 58px;
          border: 1px solid #e6e6e6;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            span {
              color: #548ad3;
            }
            .r {
              border-left: 1px solid #548ad3;
              border-right: 1px solid #548ad3;
            }
            .l {
              border-top: 1px solid #548ad3;
              border-bottom: 1px solid #548ad3;
            }
          }
          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.25s ease-in-out;
            span {
              color: #939ea8;
              font-size: 17px;
              font-weight: 700;
              line-height: 20px;
            }
          }
          .r {
            height: 100%;
            width: 70%;
            border-left: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
          }
          .l {
            border-top: 1px solid #e6e6e6;
            border-bottom: 1px solid #e6e6e6;
            height: 70%;
            width: 100%;
          }
        }
      }

      .working_space {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    padding: 32px 0;
    margin: 0 57px;
    align-items: center;
    gap: 30px;
    border-top: 1px solid #e6e6e6;
    > div {
      display: flex;
      align-items: center;
    }
    > a {
      margin-left: auto;
    }
  }
}
