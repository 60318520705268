@import "../../../index.scss";
.container {
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  max-height: 80vh;
  padding: 17px;
  gap: 17px;
  .img_container {
    width: 25px;
    height: 25px;
  }
  > .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: static;
    top: 0;
    text-align: center;
    padding: 20px 8px;
    background-image: url("./assets/header_background.jpg");
    height: 100px;
    width: 100%;
    border-radius: $borderRadius;
    h2 {
      font-size: 36px;
      font-weight: 600;
      line-height: 26px;
      color: white;
      margin: auto;
    }

    h4 {
      font-size: 22px;
      font-weight: 600;
      line-height: 26px;
      color: white;
      margin: auto;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    max-height: 500px;
    gap: 19px;

    .left {
      width: 830px;
      overflow: scroll;
    }

    .right {
      width: 356px;
      height: 476px;
    }
  }
}
