@import "../../index.scss";

.dashboard_link {
  font-size: 16px;
  color: #575757;
  margin-top: 30px;
  margin-left: 3px;

  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
}
