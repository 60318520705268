@import "../../../index.scss";

.container {
  max-width: 270px;
  min-width: 270px;
  width: 100%;
  height: calc(100vh - 100px);
  background: #fff;
  border-right: 1px solid #e6e6e6;
  box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.1);
  color: #575757;
  transition: 0.5s all ease-in-out;
  width: 700px;
  overflow: scroll;
  border-radius: $borderRadius;
  box-sizing: border-box;

  .nav_group {
    height: 20px;
    margin-top: 40px;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    overflow: hidden; /* Prevent layout breaking */
    white-space: nowrap; /* Prevent wrapping */
    text-overflow: ellipsis; /* Add ellipsis when text overflows */
    transition: $defaultTransition; /* Smooth transition for opacity */

    h5 {
      padding: 0 20px;
      font-size: 16px;
      font-weight: 700;
      color: #548ad3;
      line-height: 19px;
    }

    .line_break {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
      height: 1px;
      background: #e6e6e6;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  .nav_item {
    border-bottom: 1px solid #e6e6e6;
    border-radius: $borderRadius;
    height: 48px;
    display: flex;
    align-items: center; /* Center the contents vertically */

    &.active {
      background: $defaultGradient;
      color: white;

      svg {
        color: white;
        transition: $defaultTransition;
      }

      a {
        color: white;
        transition: $defaultTransition;
      }
      span {
        color: white;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    a {
      height: 48px;
      padding: 10px 22px;
      display: flex;
      align-items: center; /* Vertically center icon and name */
      width: 100%;
      gap: 20px;
      color: #575757;
      font-size: 16px;
      font-weight: 600;
      transition: $defaultTransition;
      text-decoration: none;
      border-radius: $borderRadius;

      &.disabled {
        pointer-events: none;
      }

      &:hover {
        background: $defaultGradient;
        color: white;

        svg {
          color: white;
          transition: $defaultTransition;
        }
        span {
          transition: $defaultTransition;
          color: white;
        }
      }

      color: #808d98;
    }
    .icon {
      flex-shrink: 0; /* Prevent shrinking of the icon */
      display: flex; /* Ensure icon is centered */
      justify-content: center;
      align-items: center;
    }

    span {
      overflow: hidden; /* Prevent layout breaking */
      white-space: nowrap; /* Prevent wrapping */
      text-overflow: ellipsis; /* Add ellipsis when text overflows */
      transition: $defaultTransition; /* Smooth transition for opacity */
    }
  }
}

.container.hide {
  //transform: translateX(-100%);
  //margin-left: -250px;
  transition: 0.5s ease-in-out;
  will-change: transform;
  width: 68px;
  min-width: 68px;

  a {
    padding: 10px 22px;
    gap: 0;
  }
}

// .container.hide {
//   transform: translateX(-100%);
//   transition: transform 0.5s ease-in-out;
//   margin-left: -250px;
// }

.navItems,
.folderStructure {
  flex: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hidden {
  opacity: 0;
  //transform: translateX(-100%);
  pointer-events: none; /* Prevent interaction when hidden */
}

.navItems:not(.hidden),
.folderStructure:not(.hidden) {
  opacity: 1;
  transform: translateX(0);
}

/* Optional: Adjust the direction of the slide for folderStructure */
.folderStructure.hidden {
  transform: translateX(100%);
}
