@import "../../index.scss";
.container {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  &.three_ex_small {
    width: 78px;
    height: 78px;
  }
  &.two_ex_small {
    width: 100px;
    height: auto;
  }
  &.ex_small {
    width: 130px;
    height: 150px;
  }
  &.small {
    width: 150px;
    height: 160px;
  }
  &.medium {
    width: 160px;
    height: 202px;
  }
  .img_container {
    position: relative;
    border-radius: $borderRadius;
    .l,
    .r {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    .l {
      left: 0;
    }
    .r {
      right: 0;
    }
    &.three_ex_small {
      width: 78px;
      height: 78px;
      border: none;
      transition: $defaultTransition;
      &:hover {
        border: 1px solid $borderColor;
      }
    }
    &.two_ex_small {
      width: 100px;
      height: 100px;
    }
    &.ex_small {
      width: 130px;
      height: 130px;
    }
    &.small {
      width: 150px;
      height: 150px;
    }
    &.medium {
      width: 160px;
      height: 160px;
    }
    width: 320px;
    height: 300px;
    background-color: white;
    border: 1px solid #e6e6e6;
    padding: 8px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;

    span {
      font-size: 16px;
      font-family: "Akrobat";
      line-break: anywhere;

      &:first-of-type {
        color: #575757;
        font-weight: 600;
      }
      &:last-of-type {
        color: #939ea8;

        font-weight: 400;
      }
    }
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
