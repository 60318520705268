@import "../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  min-width: 12.5rem;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.35);
  border-radius: 4px;
  overflow: hidden;
  padding: 20px 0;
  z-index: 2;
  position: relative;

  a {
    color: rgb(87, 87, 87);
    font-size: 16px;
    font-weight: 600;
    padding: 0.625rem 1.375rem;
    text-decoration: none;
    transition: all 0.25s ease-in-out;
    &:hover {
      color: $mainColor;
    }
  }
}
