.layer_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 12px;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  span {
    margin-left: 5px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    color: #575757;
  }

  &:hover {
    .icons {
      opacity: 1;
    }
  }
  .icons {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 12px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}
