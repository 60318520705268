@import "../../../index.scss";
.outer_container {
  padding-left: 5px;
  padding-top: 10px;
  h4 {
    margin: 15px 0;
    font-size: 22px;
    color: $mainColor;
  }
}
.container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 5px;
}
.each_node {
  display: flex;
  align-items: center;
  gap: 5px;
  transition: 0.25s ease-in-out;
  cursor: pointer;
  span {
    font-size: 18px;
  }
  &:hover {
    span {
      color: $mainColor;
    }
    svg {
      color: $mainColor;
    }
  }
}
.folder_children {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 15px;
}
.each_node {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  transition: background-color 0.2s ease-in-out;
  position: relative;
  &.hoverable:hover {
    background-color: #e0e0e0; // Light grey hover effect
    cursor: pointer;
  }

  &.dragging {
    opacity: 0.6;
  }
}

.folder_children {
  padding-left: 16px; // Indent children folders
}
.test {
  display: flex;
  align-items: center;
  gap: 5px;
}
.placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.each_node {
  padding: 10px 0 10px 0 10px; // Base padding
  transition: padding 0.2s ease;
  &.active_drop_zone {
    background-color: aqua;
  }
}

@for $i from 1 through 10 {
  .each_node[data-level="#{$i}"] {
    padding-left: #{10px * pow(2, $i)};
  }
}

@function pow($base, $exp) {
  $result: 1;
  @for $i from 1 through $exp {
    $result: $result * $base;
  }
  @return $result;
}
