.container {
  .inner_container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e6e6e6;
    background-color: white;
    padding: 32px 26px;

    > div:first-of-type {
      display: flex;
      flex-direction: column;
      max-width: 400px;
    }
  }
  .cancel {
    display: flex;
    span:first-of-type {
      max-width: 500px;
    }
    a:last-of-type {
      margin: auto;
    }
  }
}
