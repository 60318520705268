.container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 15px;
  box-sizing: border-box;
  // flex-wrap: wrap;
  justify-content: flex-start;
  height: 100%;

  .folder_div {
    margin-top: 15px;
  }
  .list_container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    > span {
      font-size: 14px;
      color: #666666;
      font-weight: 700;
      margin-left: 20px;
    }
    .map_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      align-items: center;
    }
  }
  .button_container {
    margin: auto auto 0 auto;
  }
  img {
    width: 100px;
    height: 130px;
  }
  > label {
    margin-top: auto;
  }
}
