.container {
  display: flex;
  flex-direction: column;
  max-width: 345px;
  width: 100%;
  background-color: white;
  border-left: 1px solid rgb(230, 230, 230);
  height: calc(-80px + 100vh);
  overflow: scroll;
  input {
    border: none;
    outline: none;
  }
  svg {
    color: rgb(147, 158, 168);
    width: 28px;
    height: 28px;
    &:hover {
      color: rgb(84, 138, 211);
    }
  }
  h4 {
    font-family: "Akrobat";
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    color: rgb(84, 138, 211);
    padding: 0 16px;
    margin: 24px 0;
  }
}
