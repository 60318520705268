.container {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 12px 24px;
  font-family: "Roboto";
  p {
    text-align: center;
    color: #939ea8;
    font-size: 16px;
    margin: 1rem;
    font-weight: 400;
    > strong {
      font-weight: 600;
    }
  }
  .img_container {
    display: flex;
    gap: 30px;
    justify-content: center;
    align-items: center;
    margin: 1rem;

    div {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px solid #e6e6e6;
      width: 65px;
      height: 59px;
      border-radius: 50%;
      transition: all 0.1s ease-in-out;
      cursor: pointer;

      input {
        position: absolute;
        width: 100%;
        height: 100%;
        visibility: hidden;
      }

      &:hover {
        border: 3px solid #548ad3;

        > svg {
          color: #548ad3;
        }
      }
      > svg {
        width: 28px;
        height: 26px;
        color: #939ea8;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
