@import "../../index.scss";

.container {
  display: flex;
  align-items: center;
  padding-left: 17px;
  margin-block: 3px;

  &.error > .text {
    color: #ff4d4d;
  }

  &.info > .text {
    color: #548ad3;
  }

  .icon {
    margin-right: 17px;
    width: 17px;
    height: 17px;
  }

  .text {
    padding-top: 1px;
    height: 28px;
    color: inherit;
    font-size: 14px;
    justify-self: center;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
}
