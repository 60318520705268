.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    justify-content: center;
    width: 50%;
    margin-top: 18vh;
    padding-inline: 24px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 8vh;
      overflow: scroll;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      gap: 10px;
      > p:first-of-type {
        font-size: 22px;
        margin-top: 40px;
      }
      .slogan {
        font-size: 22px;
        font-weight: 600;
      }
      .half_container_top {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 30px;

        .button_container {
          align-self: flex-start;
          padding-top: 8px;
        }

        > span {
          color: #598dd5;
          margin-bottom: 13px;
        }
      }

      .half_container_bottom {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding-top: 30px;
        font-size: 16px;

        p.first {
          font-family: "Akrobat";
          font-weight: 800;
        }

        a {
          font-size: 16px;
          text-decoration: underline;
          text-decoration-color: #598dd5;
          padding-bottom: 2px;
        }

        p.second {
          font-family: "Akrobat";
          font-weight: 600;
          padding-bottom: 3px;
        }

        p.third {
          padding-bottom: 3px;
          color: #939ea8;
        }
      }
    }

    h2 {
      font-size: 32px;
      letter-spacing: 5px;
      color: #548ad3;
      font-weight: 800;
      margin-bottom: 10vh;
    }
    .input {
      margin-bottom: 12px;
    }

    p:last-of-type {
      margin-top: 20px;
      font-size: 12px;
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .right {
    width: 50%;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
