.container {
  width: 100%;
  .label {
    margin-left: 10px;
  }
}

.img_container {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  cursor: pointer;

  span {
    width: 300px;
  }
  svg,
  img {
    width: 30px;
    height: 30px;
  }
}
