.container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0 5px;
  border: 1px solid #808d98;
  border-radius: 4px;
  height: 39px;

  &:hover,
  &:focus {
    border: 1px solid #548ad3;
  }

  svg {
    cursor: pointer;
    color: black;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: rgb(84, 138, 211);
    }
  }
  input {
    border: none;
    outline: none;
    width: 30px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #575757;
  }
}
