.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: 992px) {
    max-width: 800px;
  }
  @media screen and (max-width: 767px) {
    max-width: 600px;
    width: 100%;
  }

  .city_input {
    display: flex;
    gap: 6px;

    > div:first-of-type {
      width: 125px;
    }

    div:last-of-type {
      flex: 1;
    }
  }
}
