.container {
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
  .header {
    background: #548ad3;

    padding: 20px;
    line-height: 1;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    color: white;
  }
  .top {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    @media screen and (max-width: 950px) {
      flex-direction: column;
    }

    .left {
      display: flex;
      width: 700px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;
      text-align: center;
      border: 2px dashed #e6e6e6;
      gap: 1.5rem;
      margin: 32px;
      @media screen and (max-width: 1300px) {
        width: fit-content;
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 32px;
      border-left: 1px solid #e6e6e6;
      .input {
        min-width: 300px;
      }
    }
    h5 {
      color: #548ad3;
      font-size: 16px;
      font-weight: 700;
    }
    p {
      color: #575757;
      font-size: 16px;
    }
  }
  .bottom {
    padding: 32px;
    .button {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        color: #548ad3;
        text-decoration: underline;
      }
      svg {
        color: #548ad3;
      }
    }
  }
}
.slide {
  position: relative;
  bottom: 0;
  // left: 25%;
  // transform: translate(-50%, 0%) !important;
  display: flex;
  width: 1000px;
}
