.container {
  background-color: white;
  padding: 27px 25px;
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 20px;

    thead {
      tr {
        color: #939ea8;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        border-bottom: 1px solid #e6e6e6;

        td {
          padding: 10px 0;
        }
        td:first-of-type {
          width: 15%;
        }
        td:nth-child(2) {
          width: 35%;
        }
        td:nth-child(3) {
          width: 15%;
        }
        td:nth-child(4) {
          width: 15%;
        }
        td:nth-child(5) {
          width: 15%;
        }
      }
    }
    tbody {
      tr {
        color: #575757;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        border-bottom: 1px solid #e6e6e6;
        padding: 20px;

        td {
          padding: 20px 0;
        }
      }
      tr:last-of-type {
        border-bottom: none;
      }
    }
  }
}
