@import "../../../../index.scss";
.sliding_container {
  position: absolute;
  width: 270px;
  height: calc(100% - 20px);
  background-color: white;
  border-radius: $borderRadius;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: scroll;
  z-index: 1; // Ensure icons remain above the sliding container
}
