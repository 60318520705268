.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (min-width: 992px) {
    width: 800px;
  }
  @media screen and (max-width: 767px) {
    max-width: 600px;
    width: 100%;
  }
}
