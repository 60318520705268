.container {
  max-width: 750px;
  width: 100%;
  overflow: scroll;
  height: 100%;
  h3 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    color: #575757;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 20px;
    &:last-of-type {
      border-top: 1px solid #e6e6e6;
      padding-top: 40px;
    }
  }
  .top {
    padding: 40px 32px 20px;

    background-color: #ffffff;
    > div {
      //   width: 100%;
      display: flex;
      align-items: center;
      gap: 32px;
      img {
        width: 122px;
        height: 122px;
        border-radius: 50%;
        object-fit: contain;
      }
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    padding: 40px 32px 20px;

    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    .table {
      width: 100%;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-bottom: 50px;

    span:last-of-type {
      color: #548ad3;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .table {
    width: 75%;
  }

  .row {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid#E6E6E6;
    span:first-of-type {
      width: 150px;
      color: #939ea8;
      font-size: 14px;
      font-weight: 700;
    }
    span:last-of-type {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
