@import "../../index.scss";

.container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
  background-color: white;
  transition: width 0.2s ease-out, padding-left 0.2s ease-out; // Include padding-left in the transition
  position: relative;

  svg {
    position: absolute;
    left: 11px;
    top: 11px;
    width: 16px;
    height: 17px;
    // Optional: Adjust SVG size or positioning if needed
  }

  &.active {
    width: 100%;
    justify-content: flex-start;
    padding-left: 11px; // Smoothly transition padding-left
    input {
      display: block;
    }
  }

  input {
    width: 100%;
    display: none;
    margin-left: 25px;
    margin-right: 10px;
    border: none;
    outline: none;
  }
}
