@import "../../../index.scss";
.container {
  display: flex;
  gap: 20px;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  padding: 0px 24px;
  margin: 10px;
  border-radius: $borderRadius;
  background: $defaultGradient;

  // background-color: $mainColor;
  svg {
    transition: 0.2s ease-in-out;
    &:hover {
      color: black;
    }
  }
  a {
    text-decoration: none;
  }
  h1,
  span {
    color: white;
  }
  h1 {
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 5px;
    text-transform: uppercase;
    font-weight: 900;
  }
  span {
    font-size: 18px;
    font-weight: 600;
  }

  .left,
  .right {
    display: flex;
    justify-content: flex-end;
  }
  .left {
    display: flex;
    gap: 25px;
    align-items: center;
  }

  .right {
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;
    .info {
      display: flex;
      gap: 5px;
    }
    .img_container {
      position: relative;
      width: 48px;
      height: 48px;
      cursor: pointer;
      .profile_settings {
        position: absolute;
        display: none;
        right: 0;
        &.show {
          display: block;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 50%;
      }
      .notification {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        right: -8px;
        top: 0;
        background: red;
      }
    }
  }
}

.style {
  width: 100%;
  height: 3px;
  background: hsl(0, 3%, 86%);
  margin-bottom: 3px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
