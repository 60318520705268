.container {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0 15px;
  .color_pallet_container {
    position: relative;
    cursor: pointer;
    .current_color {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
    .pallet {
      position: absolute;
      top: 40px;
      z-index: 99;
    }
  }
  .autocomplete {
    width: 250px;
  }

  .icon_container {
    width: 30px;
    height: 30px;
    svg {
      color: #707070;
      height: 100%;
      width: 100%;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      &:hover {
        color: #548ad3;
      }
    }
  }
}

.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    opacity: 0.9;
  }
}

.colorPicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  input {
    margin: 5px;
  }
}
