.container {
  display: flex;
  flex-direction: column;
  padding: 32px 55px;
  height: 100%;
  height: calc(100vh - 220px);
  overflow: scroll;

  p {
    color: black;
  }

  h3 {
    color: #548ad3;
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    padding: 0;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    color: #548ad3;
    margin: 24px 0 24px;
    padding-bottom: 10px;
    border-bottom: 1px dotted gray;
  }

  .list_container {
    display: flex;
    gap: 24px;
    flex-direction: column;
    .map_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 24px;
    }
  }
  .folder_div {
    display: flex;
    gap: 25px;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
}
.button_div {
  display: flex;
  gap: 15px;
  margin-top: auto;
  justify-content: center;
}
.make_smaller {
  transform: scale(0.8); /* Visually shrink */
  opacity: 1; /* Optional: Slight transparency */
  z-index: 1000; /* Ensure dragged item is on top */
  pointer-events: none; /* Prevent interactions */
  transform-origin: top left; /* Anchor scaling from the top-left corner */
}
