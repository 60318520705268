.container {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 28px;
  .folders {
    display: flex;
    gap: 25px;
  }
  .product_list {
    display: flex;
    flex-direction: column;
    gap: 47px;
    flex-wrap: wrap;
    .map_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 24px;
      margin-right: 10px;
    }
  }
}
