@import "../../index.scss";

.container {
  font-size: 16px;
  padding: 3px;
  display: inline-flex;
  cursor: pointer;
  align-self: center;
  box-sizing: border-box;
  border-radius: 8px;
  width: 160px;
  height: 48px;
  padding: 3px;
  background: $gradient2;
  background-size: 200%;
  background-position: 100% 50%;
  transition: background-position 0.4s ease;

  &.container_outline {
    background: $gradient1;
    background-position: 100% 50%;
  }

  &.enabled_container_outline:hover {
    background: $gradient2;
    background-size: 200%;
    background-position: 0% 50%;
  }

  &.selectedName {
    background: $successGreenColor;
  }
  .button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: inherit;
    border-radius: 5px;
    font-weight: 800;
    width: 100%;
    height: 42px;
    padding-top: 1px;
  }
  .outlined {
    background-color: white;
    color: #548ad3;
  }
  &.input {
    .outlined {
      color: $textMainColor;
      font-weight: 700;
    }
  }
  .contained {
    color: white;
  }

  &.enabled_container:hover {
    background-position: 0% 50%;
  }

  &:hover > .enabled_outlined_button {
    background-color: transparent;
    color: white;
  }
}

.shadow {
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
.isThreePiece,
.icon {
  width: 25px;
  height: 25px;
}
