@import "../../../../../../../index.scss";

.container {
  display: flex;

  align-items: center;
  width: 100%;
  padding: 0 20px;
  position: relative;
  width: 440px;
  gap: 10px;
  svg {
    cursor: pointer;
  }
  .first_el {
    margin-right: auto;
    width: fit-content;
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border: 1px solid $borderColor;
    border-radius: $borderRadius;
    svg {
      color: #808d98;
    }
  }
  > div:last-of-type {
    width: 180px;
    position: relative;
    border: none;
    margin: 0 15px;
  }
  p:last-of-type {
    position: absolute;
    right: 20px;
  }
}
