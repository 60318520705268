.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .button_div {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
  }
  @media screen and (min-width: 992px) {
    width: 500px;
  }
  @media screen and (max-width: 767px) {
    max-width: 600px;
    width: 100%;
  }
}
