@import "../../index.scss";

.container {
  cursor: pointer;
  position: relative;
  border-radius: $borderRadius;
  .openMore {
    position: absolute;
    bottom: 5%;
    right: 10%;
  }
  .img_container {
    overflow: hidden;
    border-radius: $borderRadius;

    &.small {
      width: 80px;
      height: 80px;
    }
    &.medium {
      width: 178px;
      height: 150px;
    }

    background-color: white;
    border: 1px solid #e6e6e6;
    padding: 20px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .info {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
      font-size: 16px;
      font-family: "Akrobat";

      &:first-of-type {
        color: #575757;
        font-weight: 600;
      }
      &:last-of-type {
        color: #939ea8;

        font-weight: 400;
      }
    }
  }
}
