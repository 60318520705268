@import "../../index.scss";

.chip {
  min-width: 104px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Akrobat";
  font-weight: 600;
  color: $textMainColor;
  cursor: pointer;
  &.active {
    background: linear-gradient(90deg, #6431e6 0%, #30aae5 100%);
    color: white;
  }

  &:hover:not(&.active) {
    // border: 2px dashed #e6e6e6;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.2);
  }
}
