@import "../../index.scss";

.header {
  margin-top: 22px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  text-transform: uppercase;
  padding-bottom: 15px;
  color: $textMainColor;
}
