.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  border: 1px dotted #e6e6e6;
  margin: 27px 27px 36px 0;
  padding: 28px 0 35px;
  > span:first-of-type {
    color: #548ad3;
    font-size: 14px;
    line-height: 18px;
    font-weight: 800;
  }
  > span:nth-child(2) {
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: "Roboto";
    margin-top: 10px;
  }
  > span:last-of-type {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #939ea8;
    text-align: center;
    max-width: 360px;
    width: 100%;
  }
}
.icons {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-bottom: 36px;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    > img {
      width: 25px;
    }
  }
}
