@import "../../index.scss";

.container {
  background: $defaultGradient;
  font-size: 20px;
  padding: 3px;
  cursor: pointer;
  align-self: center;
  box-sizing: border-box;
  border-radius: 50px;
  width: 170px;

  .button {
    background-color: white;
    color: #548ad3;
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: inherit;
    border-radius: 47px;
    font-weight: 800;
    height: 42px;
    width: 100%;

    img {
      position: absolute;
      width: 36px;
      height: 34px;
      left: 8px;
    }
  }
  .button:active {
    background-color: transparent;
    color: white;
  }
}

.shadow {
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.3);
}
