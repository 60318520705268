.container {
  display: flex;
  justify-content: center;
  gap: 60px;
  padding: 15px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  .innerContainer {
    max-width: 870px;
    width: 100%;
    h3 {
      font-size: 22px;
      font-weight: 600;
      counter-reset: #575757;
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
}
