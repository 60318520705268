@import "../../../../index.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 360px;
  border: 1px solid #e6e6e6;
  padding: 22px 39px 25px;
  background-color: white;
  box-sizing: border-box;
  border-radius: $borderRadius;

  .header {
    display: flex;
    flex-direction: column;
    text-transform: capitalize;
    span:first-of-type {
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.5px;
      line-height: 29px;
      color: #548ad3;
    }
    span:nth-child(2) {
      font-weight: 700;
      font-size: 36px;
      line-height: 43px;
      color: #575757;
    }
    span:last-of-type {
      color: #939ea8;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
  > p {
    font-size: 16px;
    font-family: "Roboto";
    font-weight: 400;
    line-height: 21px;
  }
  .perk_contanier {
    display: flex;
    flex-direction: column;
    max-height: 80px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    &.active {
      max-height: 300px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #575757;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      padding: 10px 0;
    }
  }
  > div {
    display: flex;
    align-items: center;
  }
  .show_more {
    margin: 0 auto;

    > svg {
      transition: all 0.25s ease-out;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .price {
    display: flex;
    align-items: baseline;
    gap: 10px;
    span {
      color: #575757;
      font-size: 36px;
      font-weight: 600;
      line-height: 43px;
    }
    span:last-of-type {
      color: #939ea8;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
