.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  @media screen and (max-width: 992px) {
    width: 90%;
  }
}
