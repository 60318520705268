@import "../../../../../../index.scss";
.wrapper {
  display: flex;
  justify-content: center; /* Keeps the container centered */
  align-items: center;
  width: 100%;
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  border-radius: $borderRadius;
  background-color: white;
  // overflow: hidden;
  margin: auto;
  position: relative;
}

.innerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.actionContainer {
  transition: opacity 0.2s ease-in-out;
  opacity: 1;

  &.fadeOut {
    opacity: 0;
  }

  &.fadeIn {
    opacity: 1;
  }
}
