.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  .list_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 992px) {
    width: 400px;
  }
  @media screen and (max-width: 767px) {
    max-width: 600px;
    width: 100%;
  }
}
