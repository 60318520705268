.container {
  display: flex;
  height: 100vh;
  .left {
    display: flex;
    justify-content: center;
    //align-items: center;
    width: 50%;
    padding-inline: 24px;
    margin-top: 18vh;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-top: 8vh;
      overflow: scroll;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      max-width: 360px;
      width: 100%;
      gap: 10px;
      > span {
        margin-bottom: 20px;
      }
      .slogan {
        font-size: 22px;
        font-weight: 600;
      }
    }
    h2 {
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 5px;
      color: #548ad3;
      margin-bottom: 68px;
      font-weight: 800;
    }
    span {
      color: #548ad3;
    }
    .message_container {
      height: 10px;
      position: relative;

      & > div {
        max-width: 260px;
        position: absolute;
      }
    }

    .buttons_container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  .right {
    width: 50%;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
