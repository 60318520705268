.container {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  margin-top: 80px;

  @media screen and (max-width: 767px) {
    flex-direction: row;
    max-height: 80px;
    margin-top: 20px;
  }
  .nav_item {
    display: flex;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid #e6e6e6;
    padding: 10px;
    transition: 0.2s all ease-in-out;
    cursor: pointer;
    color: #575757;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    &.active {
      svg {
        color: #548ad3;
      }
      span {
        color: #548ad3;
      }
    }
    &.disabled {
      cursor: not-allowed; // Cursor remains effective
      color: #ccc; // Optional: visually indicate disabled state
      pointer-events: auto; // Allow pointer events on the parent

      & > * {
        pointer-events: none; // Disable interactions for child elements
      }
    }

    &:hover {
      color: #548ad3;

      svg {
        color: #548ad3;
      }
    }
    svg {
      color: #808d98;
    }
  }
}
