.container {
  display: flex;
  height: 100vh;
  overflow-x: scroll;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 70%;
    overflow-x: scroll;
    @media screen and (max-width: 1080px) {
      min-width: 900px;
      width: 100%;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0 40px 0;
      height: 100%;
      gap: 30px;
      overflow-x: scroll;
      > span {
        margin-bottom: 20px;
      }

      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h2 {
          margin: 0;
          color: black;
          font-family: "Roboto";
          font-size: 16px;
          letter-spacing: 0;
          margin-left: 50px;
        }

        .switcher_box {
          display: flex;
          height: 60px;
          background-color: #eef0fe;
          border-radius: 36px;

          .yearly {
            margin-left: -8px;
          }

          .monthly,
          .yearly {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 148px;
            border-radius: 36px;
            font-family: "Roboto";
            line-height: 18px;
            padding-top: 2px;

            &.active {
              position: relative;
              background: white;
            }

            &.active::before {
              content: "";
              position: absolute;
              border-radius: 32px;
              inset: 0;
              border: 5px solid transparent;
              background: linear-gradient(30deg, #665ae3, #6fc9e0) border-box;
              mask: linear-gradient(#000 0 0) padding-box,
                linear-gradient(#000 0 0);
              mask-composite: exclude;
            }

            > span {
              font-weight: 600;
              color: black;
            }

            > div {
              font-size: 14px;
              background: linear-gradient(90deg, #665ae3, #dd7c82 50%);
              background-size: 200%; /* Set up for animation */
              background-clip: text;
              -webkit-background-clip: text;
              color: transparent;
              -webkit-text-fill-color: transparent; /* Make text transparent to show background */
              font-weight: 900;

              span:first-of-type {
                font-size: 18px;
              }
              span:last-of-type {
                font-size: 14px;
              }
            }
          }
        }
      }

      .plans_container {
        display: flex;
        gap: 30px;
      }

      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-inline: 50px 75px;
        div {
          text-transform: uppercase;
        }
      }
    }

    h2 {
      font-size: 32px;
      line-height: 38px;
      letter-spacing: 5px;
      color: #548ad3;
      margin-bottom: 55px;
    }
  }
  .middle {
    width: 10px;
    background: linear-gradient(
      120deg,
      #592fe6 0%,
      #6fc9e0 55%,
      #7bb0dc 89%,
      #7ca6dd 100%
    );

    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
  .right {
    width: 30%;
    @media screen and (max-width: 1080px) {
      display: none;
    }
  }
}
