.menu {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 38px;
  width: 38px;
  .line {
    fill: none;
    stroke: white;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .line1 {
    stroke-dasharray: 60 207;
  }

  .line2 {
    stroke-dasharray: 60 60;
  }

  .line3 {
    stroke-dasharray: 60 207;
  }

  &.opened {
    .line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }

    .line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
    }

    .line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
    }
  }
}
