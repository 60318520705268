@import "../../../../../../index.scss";

.container {
  .folder_map_container {
    margin: 10px 0;
    .map_container {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      transition: $defaultTransition;
      margin: 5px 0;
      border-bottom: 1px solid transparent;
      border-radius: $borderRadius;

      span:first-of-type {
        font-size: 14px;
        color: #666666;
        font-weight: 700;
        padding-left: 20px;
      }
      span:last-of-type {
        color: $mainColor;
        font-size: 12px;
        font-weight: 700;
        padding-right: 10px;
      }
      &:hover {
        border-bottom: 1px solid $borderColor;
        color: white;
        border-radius: 2px;
      }
    }
  }
}
