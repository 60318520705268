.container {
  width: 100%;
  max-width: 750px;
  overflow: scroll;
  height: 100%;

  h3 {
    font-size: 22px;
    font-weight: 600;
    counter-reset: #575757;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .top {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    padding: 40px 32px 20px;

    background-color: #ffffff;

    > div:first-of-type {
      display: flex;
      flex-direction: row;
      gap: 50px;
      @media screen and (max-width: 1050px) {
        flex-direction: column;
      }
    }
    > span {
      font-size: 16px;
      color: #548ad3;
      text-decoration: underline;
      display: block;
      margin: 25px 0 25px auto;
      text-align: right;
    }
    h4 {
      font-size: 14px;
      font-weight: 700;
      color: #939ea8;
    }
    > div > div > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 230px;
      height: 100px;
      box-sizing: border-box;
      border: 1px solid #e6e6e6;
      letter-spacing: 5px;
      font-size: 32px;
      font-weight: 600;
      padding: 18px;
    }
    .left,
    .right {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .left {
      > div {
        color: #548ad3;
      }
    }
    .right {
      > div {
        background-color: #575757;
        color: white;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    padding: 40px 32px 20px;
    background-color: #ffffff;
    margin-bottom: 50px;

    .row {
      display: flex;
      padding: 15px 0;
      border-bottom: 1px solid#E6E6E6;
      span:first-of-type {
        min-width: 220px;
        color: #939ea8;
        font-size: 14px;
        font-weight: 700;
      }
    }
    > span {
      font-size: 16px;
      color: #548ad3;
      text-decoration: underline;
      display: block;
      margin: 25px 0 25px auto;
      text-align: right;
    }
    .answer {
      font-size: 16px;
      font-weight: 800;
      color: "#575757";
    }
  }
}
