.container {
  display: inline-flex;
  gap: 10px;
  padding: 5px;
  align-items: center;
  justify-content: center;
  margin: 25px 0 10px 0;
  cursor: pointer;
  span {
    font-size: 16px;
    color: #548ad3;
    text-decoration: underline;
  }
}
