@import "../../../../../../index.scss";

.container {
  display: flex;
  flex-direction: column;
  max-width: 350px;
  width: 100%;
  background-color: white;
  border-left: 1px solid rgb(230, 230, 230);
  height: calc(-80px + 100vh);
  border-radius: $borderRadius;
  .ex_container {
    height: 95%;
    margin: auto;
    overflow-y: scroll;
  }
  h3 {
    text-align: center;
    background-color: #f8f8f8;
    padding: 8px 0;
    font-size: 14px;
    text-transform: uppercase;
    color: #71767a;
    font-weight: 700;
  }
  .info {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0;
  }
  .top_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  .input_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 390px;
    gap: 15px;
    margin: auto;
    > div {
      max-width: 100px;
      width: 100%;
    }
  }

  .bottom {
    h4 {
      margin-left: 20px;
    }
  }
  .middle {
    padding: 0 20px;
    > div:first-of-type {
      display: flex;
      align-items: center;
      max-width: 390px;
      margin: auto;
      gap: 20px;
      margin-bottom: 15px;
      span {
        color: $mainColor;
        font-weight: 700;
      }
    }
    .shape_container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 48px;
      height: 48px;
      padding: 5px 0;
      border: 1px solid #e6e6e6;
      transition: 0.2s ease-in-out;
      border-radius: $borderRadius;
      &:first-of-type {
        margin-left: auto;
      }
      &:hover {
        background: $mainColor;
        svg {
          color: white;
        }
      }
      svg {
        color: $mainColor;
        width: 30px;
        height: 30px;
      }
    }
  }
  .img_container {
    width: 92px;
    height: 92px;
    padding: 2px;
    object-fit: cover;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e6e6e6;
    border-radius: $borderRadius;

    img {
      object-fit: cover;
      width: auto;
      height: 100%;
    }
  }
  input {
    border: none;
    outline: none;
  }
  svg {
    color: rgb(147, 158, 168);
    width: 24px;
    height: 24px;
    &:hover {
      color: rgb(84, 138, 211);
    }
  }
  h4 {
    font-family: "Akrobat";
    text-transform: uppercase;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    color: rgb(84, 138, 211);
    margin: 21px 0;
  }
}
.layer_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 12px;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  span {
    margin-left: 5px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    color: #575757;
  }

  &:hover {
    .icons {
      opacity: 1;
    }
  }
  .icons {
    display: flex;
    margin-left: auto;
    align-items: center;
    gap: 12px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}
