.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 750px;
  overflow: scroll;
  height: 100%;
  h3 {
    font-size: 22px;
    font-weight: 600;
    counter-reset: #575757;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .top {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    padding: 40px 32px 20px;
    .row {
      span:first-of-type {
        min-width: 200px;
        font-size: 14px;
        color: #939ea8;
        font-weight: 700;
      }
      span:last-of-type {
        font-size: 16px;
        font-weight: 700;
        color: #575757;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    padding: 40px 32px 20px;
    margin-bottom: 50px;
    border: 1px solid #e6e6e6;
    background-color: #ffffff;
    .row:first-child {
      justify-content: space-between;
      span:first-of-type {
        color: #939ea8;
      }
    }
    .row {
      gap: 15px;
      align-items: center;

      span {
        font-size: 18px;
        font-weight: 600;
        color: #575757;
      }
      &:not(:first-of-type) {
        padding: 15px 25px;
      }
      svg {
        margin-left: auto;
      }
      img {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        object-fit: contain;
      }
    }
  }

  .row {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid#E6E6E6;
  }
}
