@import "../../../index.scss";
.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 700px;
  @media screen and (max-width: 992px) {
    width: 100%;
    box-sizing: border-box;
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    .input_container {
      display: flex;
      gap: 15px;
      div {
        width: 100%;
      }
    }
    textarea {
      min-height: 150px;
      max-width: 100%;
      box-sizing: border-box;
      min-width: 100%;
      padding: 15px;
      border-radius: 0px;
      &.error {
        border: 1.4px solid $errorColor;
      }
    }
    div {
      width: 100%;
    }
  }
  > p {
    color: #575757;
    font-size: 16px;
    font-weight: 400;
    font-family: "Akrobat";
  }
}
