@import "../../index.scss";
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: 50px;
  background: linear-gradient(
      90deg,
      rgba(89, 141, 213, 0.1) 33%,
      rgba(89, 141, 213, 0.5) 50%,
      rgba(89, 141, 213, 0.1) 66%
    )
    #f2f2f2;
  padding: 13px 42px;

  background-size: 300% 100%;
  animation: l1 1s infinite linear;
  font-weight: 800;
  border-radius: $borderRadius;

  span {
    color: $mainColor;
    z-index: 9;
  }
}
@keyframes l1 {
  0% {
    background-position: right;
  }
}
